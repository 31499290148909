@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@import 'bootstrap/dist/css/bootstrap.min.css';

.perecluck{
    z-index: 2;
    background: black;
    padding: 2px;
}
.w-100px{
    width: 100px;
}